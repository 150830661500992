import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ButtonMain from "../../../CommonComponents/ButtonMain";
import { fontSizes } from "../../../CommonComponents/Sizes.jsx";
import CustomInput from "../../../CommonComponents/Input.jsx";

const pxToRem = (px) => `${px / 16}rem`;

const WidgetContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: ${pxToRem(1800)};
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${pxToRem(4)};
  position: relative; 
  width: 100%;
  max-width: ${pxToRem(1800)};
`;

const BackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${pxToRem(4)};
  opacity:80%;
  background: #281E71;
  mix-blend-mode: multiply;
  z-index: -1; 
`;

const StatusText = styled.span`
  font-size: ${pxToRem(32)};
  color: #fff;
  background-color: #101820;
  padding: ${pxToRem(22)} ${pxToRem(37)};
  border-radius: ${pxToRem(4)};
`;

const AdditionalText = styled.span`
  font-size: ${pxToRem(32)};
  color: #fff;
  margin-left: ${pxToRem(31)};
`;

const Timer = styled.span`
  font-size: ${pxToRem(40)};
  color: #fff;
  margin-left: auto;
  margin-right: auto;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 46.3%;
`;

const Input = styled(CustomInput)`
  padding: ${pxToRem(28)} ${pxToRem(49)};
  &:focus {
    outline: 0.1875em solid rgba(149, 224, 178, 1);
    border-bottom: 0.1875em solid rgba(16, 24, 32, 1);
  }
`;

const ActionButton = styled(ButtonMain)`
  padding: ${pxToRem(30)} ${pxToRem(12)};
  font-size: ${fontSizes.button};
`;

const ShiftWidget = () => {
  const [timer, setTimer] = useState({ hours: 0, minutes: 15, seconds: 0 });
  const [currentStatus, setCurrentStatus] = useState("waiting"); // Возможные значения: waiting, open, no-active, opening, closing

  useEffect(() => {
    let interval;
    if (currentStatus === "waiting" || currentStatus === "open") {
      interval = setInterval(() => {
        setTimer((prev) => {
          const { hours, minutes, seconds } = prev;
          if (seconds > 0) return { ...prev, seconds: seconds - 1 };
          if (minutes > 0) return { ...prev, minutes: minutes - 1, seconds: 59 };
          if (hours > 0) return { hours: hours - 1, minutes: 59, seconds: 59 };
          clearInterval(interval);
          return prev;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [currentStatus]);

  const handleAction = () => {
    if (currentStatus === "waiting") setCurrentStatus("opening");
    else if (currentStatus === "open") setCurrentStatus("closing");
    else if (currentStatus === "closing") setCurrentStatus("waiting");
  };

  const formatTime = ({ hours, minutes, seconds }) =>
    `${String(hours).padStart(2, "0")}ч : ${String(minutes).padStart(2, "0")}м : ${String(seconds).padStart(2, "0")}с`;

  return (
    <WidgetContainer>
      {currentStatus === "waiting" && (
        <StatusContainer>
          <BackgroundLayer />
          <StatusText>
            Статус смены: <span style={{ color: "#FFFA8A" }}>ожидает открытия</span>
          </StatusText>
          <AdditionalText>Ваша смена начнется через:</AdditionalText>
          <Timer>{formatTime(timer)}</Timer>
          <ActionButton onClick={handleAction}>Открыть смену</ActionButton>
        </StatusContainer>
      )}
      {currentStatus === "open" && (
        <StatusContainer>
          <StatusText>
            Статус смены: <span style={{ color: "#95E0B2" }}>смена открыта</span>
          </StatusText>
          <AdditionalText>До окончания смены осталось:</AdditionalText>
          <Timer>{formatTime(timer)}</Timer>
          <ActionButton onClick={handleAction}>Закрыть смену</ActionButton>
        </StatusContainer>
      )}
      {currentStatus === "no-active" && (
        <StatusContainer>
          <StatusText>
            Статус смены: <strong>у вас нет активной смены</strong>
          </StatusText>
          <AdditionalText>Следующая смена через: </AdditionalText>
          <Timer>{formatTime(timer)}</Timer>
        </StatusContainer>
      )}
      {currentStatus === "opening" && (
        <StatusContainer>
          <StatusText style={{ color: "#95E0B2" }}>Открытие смены</StatusText>
          <AdditionalText>
            Введите количество денег в кассе
            </AdditionalText>
            <InputContainer>
            <Input type="text" placeholder="Введите значение..." />
          <ActionButton onClick={() => setCurrentStatus("open")}>Подтвердить</ActionButton>
          </InputContainer>
        </StatusContainer>
      )}
      {currentStatus === "closing" && (
        <StatusContainer>
          <StatusText>
            <strong>Закрытие смены</strong>
          </StatusText>
          <AdditionalText>
            Введите количество денег в кассе
            </AdditionalText>
            <InputContainer>
            <Input type="text" placeholder="Введите значение..." />
          <ActionButton onClick={() => setCurrentStatus("waiting")}>Подтвердить</ActionButton>
          </InputContainer>
        </StatusContainer>
      )}
    </WidgetContainer>
  );
};

export default ShiftWidget;
