import styled from "styled-components";
import { spacing, fontSizes } from "../../CommonComponents/Sizes.jsx";

const pxToRem = (px) => `${px / 16}rem`;

export const MainManagement = styled.div``;

export const MainContent = styled.div`
  margin-top: ${pxToRem(52)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${pxToRem(40)};;
  max-width: ${pxToRem(1800)};
  width:100%;
  justify-content: space-between;
`;

export const AdminColumn = styled.div`
  width:15%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AdminColumnBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity:80%;
  background: #281e71;
  mix-blend-mode: multiply;
  z-index: -1; 
  border-radius: ${pxToRem(4)};
`;

export const AdminTitle = styled.h3`
  border-radius: ${pxToRem(4)};
  padding: ${pxToRem(25)};
  text-align: center; 
  font-size: ${fontSizes.button};
  font-weight: 600;
  border-bottom: 2px solid #95E0B2;
  background: #101820;
`;

export const AdminItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ isActive }) => (isActive ? "0" : "17px")};
  margin-left: ${({ isActive }) => (isActive ? "0" : "17px")};
  background-color: ${({ isActive }) => (isActive ? "#101820" : "transparent")};
  border: ${({ isActive }) => (isActive ? "2px solid #95E0B2" : "none")};
  border-radius: ${pxToRem(4)};
  padding-top: ${({ isActive }) => (isActive ? pxToRem(6) : "0")};
  padding-bottom: ${({ isActive }) => (isActive ? pxToRem(6) : "0")};
  padding-left: ${({ isActive }) => (isActive ? pxToRem(18) : "0")};
  &:last-child {
    margin-bottom: ${pxToRem(17)};
  }
`;


export const AdminCircle = styled.div`
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: ${pxToRem(22)};
`;

export const AdminName = styled.span`
  font-size: ${fontSizes.subheading};
  color: #FFFFFF;

`;
