import React from "react";
import * as Styled from "./StyleMainPage.jsx";
import logo from "../../img/Logo1.svg";
import AnimatedGradientBackground from "../AnimatedGradient.jsx";
import UserMenu from "../CommonComponents/UserMenu/UserMenu.jsx";

const MainPage = () => {
  return (
    <Styled.MainPageContainer>
      <AnimatedGradientBackground />
      <Styled.VerticalStripe />
      <UserMenu />

      <Styled.GridContainer>
        <Styled.Logo src={logo} alt="Логотип" />
        <Styled.Title>АДМИН-ПАНЕЛЬ</Styled.Title>

        <Styled.LinkList>
          <Styled.ButtonMainPage onClick={() => window.location.href = "/login"}>
            Вход
          </Styled.ButtonMainPage>
          <Styled.ButtonMainPage onClick={() => window.location.href = "/booking"}>
            Бронирование
          </Styled.ButtonMainPage>
          <Styled.ButtonMainPage onClick={() => window.location.href = "/loyalty"}>
            Лояльность
          </Styled.ButtonMainPage>
          <Styled.ButtonMainPage onClick={() => window.location.href = "/sessions"}>
            Сессии
          </Styled.ButtonMainPage>
        </Styled.LinkList>

      </Styled.GridContainer>
    </Styled.MainPageContainer>
  );
};

export default MainPage;
