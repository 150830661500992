import React, { useState } from "react";
import Morning from "../../../../img/Calendar/Morning.svg";
import Day from "../../../../img/Calendar/Day.svg";
import Night from "../../../../img/Calendar/Night.svg";
import { ReturnButton, ForwardButton } from "../../../../img/icons.js";
import * as Styled from "./StyleScheduleTable.jsx";

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
const daysOfWeek = [
  "понедельник",
  "вторник",
  "среда",
  "четверг",
  "пятница",
  "суббота",
  "воскресенье",
];

const getDaysInMonth = (year, month) => {
  const date = new Date(year, month, 1);
  const days = [];
  const firstDayIndex = (date.getDay() + 6) % 7; // Преобразуем Sunday (0) в последний день (6)

  // Добавляем пустые дни перед началом месяца
  for (let i = 0; i < firstDayIndex; i++) {
    days.push(null);
  }

  // Добавляем дни месяца
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  // Добавляем пустые дни в конец, чтобы неделя была полной
  while (days.length % 7 !== 0) {
    days.push(null);
  }

  return days;
};

const getWeeksInMonth = (days) => Math.ceil(days.length / 7);

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const days = getDaysInMonth(year, month);
  const weeks = getWeeksInMonth(days);

  const handlePrevMonth = () => {
    setCurrentDate(new Date(year, month - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(year, month + 1, 1));
  };

  const isCurrentDay = (day) =>
    day &&
    day.getDate() === new Date().getDate() &&
    day.getMonth() === new Date().getMonth() &&
    day.getFullYear() === new Date().getFullYear();

  return (
    <Styled.CalendarWrapper>
      <Styled.BackgroundLayer />
      <Styled.Header>
        <Styled.Title>Календарь</Styled.Title>
        <Styled.MonthsSwitcher>
          <Styled.NavButton
            src={ReturnButton}
            alt="Назад"
            onClick={handlePrevMonth}
          />
          <Styled.MonthContainer>
            <p>
              {months[month]} {year}
            </p>
          </Styled.MonthContainer>
          <Styled.NavButton
            src={ForwardButton}
            alt="Вперед"
            onClick={handleNextMonth}
          />
        </Styled.MonthsSwitcher>
      </Styled.Header>

      <Styled.UnifiedTable>
        <Styled.DaysOfWeekContainer>
          <tr>
            {[" ", ...daysOfWeek].map((header, index) => (
              <Styled.DayOfWeek key={index}>{header}</Styled.DayOfWeek>
            ))}
          </tr>
        </Styled.DaysOfWeekContainer>

        <Styled.TableBody>
          {[...Array(weeks)].map((_, weekIndex) => {
            const weekDays = days.slice(weekIndex * 7, (weekIndex + 1) * 7);

            return (
              <tr key={weekIndex}>
                <Styled.DayCell>
                  <Styled.EmptyRow> </Styled.EmptyRow>
                  <Styled.WeekColumn>
                    <Styled.WeekNum>{weekIndex + 1}-я неделя</Styled.WeekNum>
                    <Styled.TimeColumn>
                      {["утро", "день", "ночь"].map((timeOfDay, index) => (
                        <Styled.TimeItem key={index}>
                          <Styled.TimeText>{timeOfDay}</Styled.TimeText>
                          <Styled.TimeIcon
                            src={
                              timeOfDay === "утро"
                                ? Morning
                                : timeOfDay === "день"
                                ? Day
                                : Night
                            }
                            alt={timeOfDay}
                          />
                        </Styled.TimeItem>
                      ))}
                    </Styled.TimeColumn>
                  </Styled.WeekColumn>
                </Styled.DayCell>

                {weekDays.map((day, index) => (
                  <Styled.DateBorder key={index} isCurrentDay={isCurrentDay(day)}>
                    <Styled.DayCell>
                      <Styled.DateContainer>
                        {day instanceof Date ? day.getDate() : ""}
                      </Styled.DateContainer>
                    </Styled.DayCell>
                    <Styled.TimeSlot></Styled.TimeSlot>
                    <Styled.TimeSlotWrapper>
                      <Styled.TimeSlot></Styled.TimeSlot>
                      <Styled.TimeSlot></Styled.TimeSlot>
                    </Styled.TimeSlotWrapper>
                    <Styled.TimeSlot></Styled.TimeSlot>
                  </Styled.DateBorder>
                ))}
              </tr>
            );
          })}
        </Styled.TableBody>
      </Styled.UnifiedTable>
    </Styled.CalendarWrapper>
  );
};

export default Calendar;
