import './styles/App.css';
import React from 'react';
import MainPage from './components/MainPage/MainPage.jsx';
import Login from './components/Login/Login.jsx';
import Loyalty from './components/Loyalty/Loyalty.jsx';
import Booking from './components/Booking/Booking.jsx';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NotFound } from './NotFound.jsx';
import Profile from './components/Profile/Profile.jsx';
import Session from './components/Profile/Sessions.jsx';
import CheckToken from './components/Login/CheckToken.jsx';
import MainManagement from './components/Profile/Management/MainManagement.jsx';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <CheckToken>
                <MainPage />
              </CheckToken>
            }
          />
          <Route
            path="/loyalty"
            element={
              <CheckToken>
                <Loyalty />
              </CheckToken>
            }
          />
          <Route
            path="/booking"
            element={
              <CheckToken>
                <Booking />
              </CheckToken>
            }
          />
          <Route
            path="/profile"
            element={
              <CheckToken>
                <Profile />
              </CheckToken>
            }
          />
          <Route
            path="/profile/schedule"
            element={
              <CheckToken>
                <MainManagement />
              </CheckToken>
            }
          />
          <Route
            path="/sessions"
            element={
              <CheckToken>
                <Session />
              </CheckToken>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
