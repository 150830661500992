import styled from "styled-components";
import { spacing, fontSizes } from "../CommonComponents/Sizes.jsx";
import ButtonMain from "../CommonComponents/ButtonMain.jsx";

export const Login = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const Logo = styled.img`
  position: fixed;
  top: 3.75rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 25.5rem;
  height: auto;
  margin-bottom: 1.25rem;

  @media (max-width: 1440px) {
    height: 3.75rem;
  }
`;

export const Title = styled.div`
    font-size: 3rem;
    text-transform: uppercase;
    margin: 0 3rem 1.25rem 3rem;

    @media (max-width: 1440px) {
        font-size: 2rem;
        margin-bottom: 0.25;
    }
`;

export const Form = styled.form`
    display: flex;
    z-index: 2;
    align-items: center;
    flex-direction: column;
    gap: 2.5rem;

    @media (max-width: 1440px) {
        width: 20rem;
        gap: 2rem;
    }
`;


export const ButtonLogin = styled(ButtonMain)`
    padding: 1rem 1.68rem;
    font-size: ${fontSizes.subheading};
    border-radius: 0.375rem;
    text-align: center;

    @media (max-width: 1024px) {
        font-size: ${fontSizes.button};
        padding: 0.7rem 1.176rem;
    }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: ${fontSizes.button};

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptLarge};
  }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing.small};
`;

export const ForgotPasswordButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: underline;
  font-size: ${fontSizes.button};

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptMedium};
  }
`;

export const ReturnButtonContainer = styled.div`
  position: absolute;
  top: ${spacing.small};
  left: ${spacing.small};
  z-index: 2;
`;

export const VerticalStripe = styled.div`
  position: absolute;
  left: 50%;
  width: 37.5rem;
  height: 100%;
  background-color: rgba(40, 30, 113, 1);
  opacity: 0.5;
  transform: translateX(-50%);
  mix-blend-mode: multiply;

  @media (max-width: 1024px) {
    width: 30rem;
  }
`;