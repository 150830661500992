import React from "react";
import * as Styled from "./StylesProfileHeader.jsx";
import { Logo, ReturnButton } from "../../../img/icons.js";

const ProfileHeader = () => {
  const currentPath = window.location.pathname; // Получаем текущий путь

  return (
    <Styled.Header>
      <Styled.HeaderBackground />
      <Styled.HeaderContent>
        <Styled.Logo src={Logo} alt="Логотип" onClick={() => (window.location.href = "/")} />
        <Styled.ReturnButton src={ReturnButton} alt="Назад" onClick={() => (window.location.href = "/")} />
        <Styled.Title>Личный кабинет</Styled.Title>
        
        <Styled.HeaderButtons>
          <Styled.HeaderButton 
            onClick={() => window.location.href = "/profile/schedule"} 
            isActive={currentPath === "/profile/schedule"} // Проверяем активен ли путь
          >
            Управление сменами
          </Styled.HeaderButton>
          <Styled.HeaderButton 
            onClick={() => window.location.href = "/profile/settings"} 
            isActive={currentPath === "/profile/settings"} // Проверяем активен ли путь
          >
            Настройки
          </Styled.HeaderButton>
          <Styled.HeaderButton 
            onClick={() => window.location.href = "/profile/security"} 
            isActive={currentPath === "/profile/security"} // Проверяем активен ли путь
          >
            Безопасность
          </Styled.HeaderButton>
        </Styled.HeaderButtons>
      </Styled.HeaderContent>
    </Styled.Header>
  );
};

export default ProfileHeader;
