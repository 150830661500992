import React from "react";
import styled from "styled-components";
import { fontSizes } from "./Sizes.jsx";

const StyledInput = styled.input`
  width: 100%;
  border-bottom: 0.1875em solid rgba(149, 224, 178, 1);
  border-radius: 0.25em;
  background-color: rgba(16, 24, 32, 1);
  padding: 0.69em 0 0.65em 1.5em;
  font-size: ${fontSizes.button};

  &:focus {
    outline: 0.1875em solid rgba(149, 224, 178, 1);
    border-bottom: 0.1875em solid rgba(16, 24, 32, 1);
  }

  &::placeholder {
    text-align: left;
    color: rgba(140, 140, 140, 1);
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
  }

  @media (max-width: 1440px) {
    padding: 0.48em 0 0.46em 1.05em;
  }
`;

const CustomInput = ({ className, ...props }) => {
  return <StyledInput className={className} {...props} />;
};

export default CustomInput;
