import React, { useEffect, useState } from "react";
import AnimatedGradientBackground from "../../AnimatedGradient.jsx";
import ProfileHeader from "../ProfileHeader/ProfileHeader.jsx";
import * as Styled from "./StyleMainManagement.jsx";
import ScheduleTable from "./Schedule/ScheduleTable.jsx";
import ShiftWidget from "./StatusWidget/Status.jsx";

const MainManagement = () => {
  const [activeAdmin, setActiveAdmin] = useState(null);

  const admins = [
    { name: "Иванов Иван", color: "#FFFA8A" },
    { name: "Петров Петр", color: "#492CFF" },
    { name: "Сидоров Сидор", color: "#2A9049" },
  ];

  //! Пока с затычками
  useEffect(() => {
    // Получаем текущего пользователя (пример с localStorage)
    const currentUser = localStorage.getItem("currentAdmin");
    setActiveAdmin(currentUser);
  }, []);

  // Если текущий пользователь не определён, выбираем первого администратора как активного
  const currentAdmin = activeAdmin || admins[0].name;

  // Сортируем администраторов: активный пользователь всегда наверху
  const sortedAdmins = admins.sort((a, b) =>
    a.name === currentAdmin ? -1 : b.name === currentAdmin ? 1 : 0
  );

  return (
    <Styled.MainManagement>
      <AnimatedGradientBackground />
      <ProfileHeader />

      <Styled.MainContent>
        <ShiftWidget />
        <Styled.CenteredContentWrapper>
          <ScheduleTable />
          <Styled.AdminColumn>
            <Styled.AdminColumnBackground />
            <Styled.AdminTitle>Администраторы</Styled.AdminTitle>
            {sortedAdmins.map((admin, index) => (
              <Styled.AdminItem
                key={index}
                isActive={admin.name === currentAdmin}
              >
                <Styled.AdminCircle color={admin.color} />
                <Styled.AdminName>{admin.name}</Styled.AdminName>
              </Styled.AdminItem>
            ))}
          </Styled.AdminColumn>
        </Styled.CenteredContentWrapper>
      </Styled.MainContent>
    </Styled.MainManagement>
  );
};

export default MainManagement;
