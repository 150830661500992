import styled from "styled-components";
import { fontSizes } from "../../CommonComponents/Sizes.jsx";
import ButtonMain from "../../CommonComponents/ButtonMain.jsx";

export const Header = styled.header`
  display: grid;
  align-items: center;
  width: 100%;
  height: 10rem;
  padding: 0 3.75rem;
  border-radius: 0px 0px 4px 4px;
  position: relative;
  
  @media (max-width: 1920px) {
    height: 8.75rem;
    padding: 0 3.125rem;
  }
  @media (max-width: 1440px) {
    height: 6.25rem;
    padding: 0 2.5rem;
  }
`;
export const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(40, 30, 113, 1);
  opacity: 50%;
  z-index: 1;
  border-radius: 0px 0px 4px 4px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
`;

export const HeaderButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-auto-flow: column;
  gap: 2.5rem;
  height: 3.125rem;
  margin-left: auto;

  @media (max-width: 1920px) {
    gap: 1.5rem;
    height: 2.5rem;
  }
  @media (max-width: 1440px) {
    gap: 1rem;
    height: 2.1875rem;
  }
`;

export const HeaderButton = styled(ButtonMain)`
  padding: 0 0.9375rem;
  flex-grow: 1;
  max-height: 3.125rem;
  background: ${({ isActive }) => (isActive ? "#281E71" : "#95e0b2")};
  color: ${({ isActive }) => (isActive ? "white" : "#101820")};

  &:hover {
    background: #0056b3;
  }

  @media (max-width: 1920px) {
    padding: 0 0.75rem;
    font-size: ${fontSizes.adaptMedium};
  }
    
  @media (max-width: 1440px) {
    padding: 0 0.625rem;
    font-size: ${fontSizes.adaptSmall};
    max-height: 2.5rem;
  }
`;

export const Logo = styled.img`
  height: 3.125rem;
  cursor: pointer;
  @media (max-width: 1920px) {
    height: 2.8125rem;
  }
  @media (max-width: 1440px) {
    height: 2.1875rem;
  }
`;

export const Title = styled.h1`
  font-size: ${fontSizes.heading};
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  margin-left: 0.9375rem;

  @media (max-width: 1920px) {
    font-size: ${fontSizes.heading};
    margin-left: 0.75rem;
  }
  @media (max-width: 1440px) {
    font-size: ${fontSizes.subheading};
    margin-left: 0.625rem;
  }
`;

export const ReturnButton = styled.img`
  cursor: pointer;
  margin-left: 5.3125rem;
  height: 3.75rem;

  @media (max-width: 1920px) {
    margin-left: 4.375rem;
    height: 3.125rem;
  }
  @media (max-width: 1440px) {
    margin-left: 2.5rem;
    height: 2.1875rem;
  }
`;
