import React from "react";
import { fontSizes } from "./Sizes.jsx";
import styled from "styled-components";

const ButtonCustom = styled.button`
  font-size: ${fontSizes.button};
  background: rgba(149, 224, 178, 1);
  color: black;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;

  @media (max-width: 1920px) {
    font-size: ${fontSizes.adaptMedium};
  }

  @media (max-width: 1440px) {
    font-size: ${fontSizes.adaptSmall};
  }

  &:hover {
    background-color: #3f51b5;
  }

  &:active {
    background-color: rgba(40, 30, 113, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
    animation: buttonActive 300ms ease-in-out forwards;
  }
`;

const ButtonMain = ({ children, onClick, isEditing, ...props }) => (
    <ButtonCustom
      isEditing={isEditing}
      onClick={onClick}
      {...props}
    >
      {children}
    </ButtonCustom>
  );
  
export default ButtonMain;