import styled from "styled-components";
import { spacing, fontSizes } from "../CommonComponents/Sizes.jsx";
import ButtonMain from "../CommonComponents/ButtonMain.jsx";

export const MainPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const GridContainer = styled.div`
  display: flex;
  z-index: 2;
  align-items: center;
  flex-direction: column;
  gap: ${spacing.medium};
`;

export const Logo = styled.img`
  position: fixed;
  top: 3.75rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 25.5rem;
  height: auto;
  margin-bottom: 1.25rem;

  @media (max-width: 1440px) {
    height: 3.75rem;
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0 1.3125rem 1.25rem 1.3125rem;

  @media (max-width: 1440px) {
      font-size: 2rem;
      margin-bottom: ${spacing.xmedium};
  }
`;

export const LinkList = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${spacing.medium};

  @media (max-width: 1440px) {
    gap: 1.25rem;
  }
`;

export const ButtonMainPage = styled(ButtonMain)`
  padding: 1rem 7.4375rem;
  font-size: ${fontSizes.subheading};
  text-align: center;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
    padding: 0.6rem 4.4625rem;
  }
`;

export const VerticalStripe = styled.div`
  position: absolute;
  left: 50%;
  width: 37.5rem;
  height: 100%;
  background-color: rgba(40, 30, 113, 1);
  opacity: 0.5;
  transform: translateX(-50%);
  mix-blend-mode: multiply;

  @media (max-width: 1440px) {
    width: 25rem;
  }
`;
